import React from 'react'
import InDevelopment from '../../components/main/InDevelopment'

export default function Franchise() {
  return (
    <>
    <InDevelopment/>
    </>
  )
}
